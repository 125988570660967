@import "library";

body.travel {
    background: $kaolin;
    >.header {
        >nav {
            display: none;
        }
    }
}

.monograph-content {
    background: $porcelain;
    padding: 0 0 8%;
    >.hero {
        background: $marble;
        >.primary {
            overflow: hidden;
            padding: 4%;
            >.lockup {
                @include flex();
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                height: 100%;
                >.heading {
                    @include flex();
                    flex-direction: column;
                    height: 100%;
                    >.label {
                        @include running(small);
                        @include uppercase(6px);
                        font-family: Empirica, sans-serif;
                        font-weight: 500;
                        color: $mud;
                        padding: 10% 0;
                        /* Manual adjust for letter-spacing issue in CSS */
                        position: relative;
                        right: -6px;
                    }
                    .title {
                        @include running($type: maxi);
                        font-weight: 200;
                        display: block;
                        color: $oxide;
                        span {
                            display: block;
                            color: $mud;
                            font-weight: 200;
                        }
                    }
                    >.label,
                    >.title-secondary {
                        @include running(small);
                        @include uppercase(6px);
                        font-family: Empirica, sans-serif;
                        font-weight: 500;
                        color: #acaaa4;
                        margin: 0;
                    }
                }
                >.description {
                    >.date {
                        @include running(small);
                        @include uppercase(6px);
                        font-family: Empirica, sans-serif;
                        font-weight: 500;
                        color: $oxide;
                    }
                    >.value {
                        margin-top: 10px;
                        &.iata {
                            @include running(small);
                            @include uppercase(6px);
                            @include flex();
                            align-items: center;
                            font-family: Empirica, sans-serif;
                            font-weight: 500;
                            color: #acaaa4;
                            letter-spacing: 2px;
                            gap: 8px;
                            >span {
                                @include flex();
                                align-items: center;
                                gap: 8px;
                                &:first-of-type {
                                    position: relative;
                                    &:after {
                                        @include pseudo();
                                        @include background-size(32px 16px);
                                        background-image: url("https://cdn2.assets-servd.host/silky-civet/production/interface/arrow_e_long.svg");
                                        background-repeat: no-repeat;
                                        position: relative;
                                        top: 0;
                                        margin: auto;
                                        width: 32px;
                                        height: 16px;
                                        flex-shrink: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        >.secondary {
            >figure {
                >.graphic {
                    height: 80vh;
                    min-height: 320px;
                    width: 100%;
                    >img {
                        object-position: center;
                        display: block;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
                >figcaption {
                    @include flex();
                    padding: 10px 0;
                    justify-content: space-between;
                    color: #acaaa4;
                    font-size: 16px;
                    display: none;
                    a {
                        color: #666;
                    }
                }
            }
        }
        @media screen and (max-width: 960px) {
        }
        @media screen and (max-width: 480px) {
        }
        &.full-viewport {
            >.primary {
                height: calc(100vh - 160px);
                min-height: 480px;
                >.lockup {
                    >.heading {
                        >.label {
                            padding: 0 0 16px;
                        }
                    }
                }
            }
            @media screen and (max-width: 640px) {
                >.primary {
                    height: calc(100vh - 80px);
                }
            }
        }
    }
    >section { 
        @include base-layout();
        &.heading {
            margin-top: 6%;
            >.content {
                max-width: 720px;
                margin: 0 auto;
                >.label {
                    @include running(small-caps);
                    font-family: Empirica, sans-serif;
                }
                >.title {
                    @include running(xlarge);
                    font-family: Empirica, sans-serif;
                    color: #000;
                }
                >.title-secondary {
                    @include running(xlarge);
                    font-family: Empirica, sans-serif;
                    color: #acaaa4;
                }
            }
        }
        >.heading {
            max-width: 720px;
            margin: 8% auto 4%;
            >h2 {
                @include running(maxi);
                font-family: Empirica, sans-serif;
                color: $oxide;
                font-weight: 200;
                margin: 20px 0 40px -80px;
                >span {
                    @include running(small);
                    @include uppercase(6px);
                    display: block;
                    font-family: Empirica, sans-serif;
                    font-weight: 500;
                    color: $mud;
                    margin: 2% 0 0;
                }
            }
        }
        >.text {
            max-width: 720px;
            margin: 2% auto 2%;
            &.introduction {
                margin-top: 8%;
                >p {
                    @include running(large);
                    color: #000;
                    font-weight: 400;
                    font-family: Empirica, sans-serif;
                    line-height: 56px;
                }
            }
            &.lombardic-capital {
                >p {
                    /*

                .inhabited-initial {
                    display: block;
                    float: left;
                    text-align: center;
                    height: 190px;
                    min-width: 30%;
                    @include running(large);
                    @include uppercase(6px);
                    font-family: Empirica, sans-serif;
                    font-weight: 400;
                    line-height: 190px !important;
                    color: $mud;
                }
                @media screen and (max-width: 480px) {
                    text-align: left;
                    .inhabited-initial {
                        min-width: 50%;
                    }
                } 
                */
                }
            }
            a {
                text-decoration: none;
                padding-bottom: 0;
                color: $oxide;
                border-bottom: 1px solid rgba(0,0,0,0.1);
                &:hover {
                    opacity: 1;
                    color: $coral;
                    border-bottom: 1px solid $coral;
                }
                &:active {
                    opacity: 1;
                    color: $coral;
                    border-bottom: 1px solid rgba(0,0,0,0.8);
                }
            }
            >p {
                @include running();
                text-align: justify;
                font-family: Empirica, sans-serif;
                color: $patina;
                /*
                &.note {
                    @include uppercase();
                    display: inline-block;
                    font-size: 12px;
                    font-weight: 500;
                    border-top: 1px solid $line;
                    padding: 2% 0;
                    text-align: center;
                    .label {
                        color: $tin;
                        display: block;
                    }
                }
                */
                & + p {
                    margin-top: 4%;
                }
            }
            >blockquote {
                float: left;
                width: 80%;
                padding: 6% 6% 6% 40px;
                font-family: Empirica, sans-serif;
                font-size: 48px;
                line-height: 64px;
                color: $oxide;
                margin-left: -12%;
                quotes: "“" "”" "‘" "’";
                display: block;
                font-weight: 200;
                &:before {
                    content: "“";
                }
                &:after {
                    content: "”";
                }
                @media screen and (max-width: 480px) {
                    border-top: 1px solid $line;
                    border-bottom: 1px solid $line;
                    float: none;
                    width: 100%;
                    margin: 8% 0;
                    padding: 8% 0;
                    text-align: left;
                    font-size: 36px;
                    line-height: 56px;
                }
            }
        }
        >.graphics {
            &.grid-distribute-evenly {
                display: grid;
                grid-gap: 24px;
                grid-auto-flow: column;
            }
            >.graphic {
                margin-bottom: 24px;
                >img {
                    width: 100%;
                    height: 100%;
                    max-width: 100%;
                    object-fit: cover;
                    object-position: top left;
                }
            }
            &.breadth-equal-to-text {
                @include page-width($type: journal);
            }
            &.breadth-preserve-size {
                img {
                    width: auto !important;
                    margin: 0 auto;
                    display: block;
                }
            }
            &.breadth-small {
                max-width: 960px;
            }
            &.breadth-medium {
                max-width: 1440px;
            }
            &.breadth-large {
                max-width: 1600px;
            }
            &.breadth-extra-large {
                max-width: 2400px;
            }
            &.breadth-expansive {
                width: 100%;
            }
        }
        >.caption {
            margin: 2% auto 6%;
            max-width: 720px;
            border-bottom: 1px solid $line;
            padding-bottom: 3%;
            >.title {
                @include uppercase();
                color: $tin;
                text-align: center;
                margin: 2% 0;
            }
            >.description {
                @include running(small);
                font-family: Empirica, sans-serif;
                color: $stone;
                span {
                    @include running(note);
                    @include uppercase();
                    font-family: Empirica, sans-serif;
                    font-weight: 500;
                    color: $dust;
                    padding-right: 16px;
                }
            }
            @media screen and (max-width: 480px) {
                >.description {
                    span {
                        display: block;
                        font-size: 1px;
                        color: transparent;
                        &:after {
                            display: block;
                            float: left;
                            font-size: 14px;
                            color: $dust;
                            content: "Top to bottom";
                        }
                    }
                }
            }
        }





        &.base {
            margin: 0 8% 8%;
            max-width: 1440px;
            @media screen and (min-width: 1600px) {
                margin: 0 auto 8%;
            }
        }
        &.running {
            >.content {
                .coupon {
                    @include uppercase();
                    @include border-radius(64px);
                    color: $tin;
                    font-size: 16px;
                    font-weight: 500;
                    background: $bergamot;
                    display: inline-block;
                    padding: 4px 12px;
                    position: relative;
                    top: -1px;
                }
                .google-map {
                    width: 100% !important;
                    max-width: 960px;
                    margin: 0 auto;
                }
                h3 {
                    @include title();
                    margin: 48px 0 24px;
                }
                p + h3 {
                }
                .card-block {
                    background: $kaolin;
                    padding: 8%;
                    &.recipe {
                        >.item {
                            display: grid;
                            grid-template-columns: 1fr 2fr 1fr;
                            padding: 24px 0;
                            border-bottom: 1px solid $line;
                            &:last-of-type {
                                border-bottom: 0;
                            }
                            >.value {
                                color: $tin;
                                padding-right: 2%;
                                font-variant-numeric: diagonal-fractions;
                            }
                            >.label {
                                padding-right: 2%;
                            }
                            >.note {
                                text-align: right;
                                a {
                                    @include external-link();
                                }
                            }
                        }
                        @media screen and (max-width: 880px) {
                            >.item {
                                grid-template-columns: 1fr 1fr;
                                >.note {
                                    text-align: left;
                                    padding: 24px 0;
                                    grid-column-start: 2;
                                    grid-column-end: 3;
                                }
                            }
                        }
                    }
                    &.guide {
                        >ol {
                            padding: 0;
                            counter-reset: cardinal;
                            >li {
                                @include running();
                                color: $patina;
                                position: relative;
                                list-style: none;
                                margin-top: 20px;
                                padding: 20px 0 0 10%;
                                border-top: 1px solid $line;
                                &:before {
                                    color: $coral;
                                    font-weight: 400;
                                    position: absolute;
                                    top: 20px;
                                    left: 0;
                                    counter-increment: cardinal;
                                    content: counter(cardinal);
                                    display: block;
                                }
                                &:first-of-type {
                                    margin-top: 0;
                                    border-top: 0;
                                    padding-top: 0;
                                    &:before {
                                        top: 0;
                                    }
                                }
                                &:last-of-type {
                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }
                }
            }
            &.directions {
                >.content {
                    background: $sand;
                    >.primary {
                        padding: 8%;
                        h3 {
                            font-size: 24px;
                            margin: 0 0 4%;
                        }
                        >ol {
                            counter-reset: cardinal;
                            padding: 0;
                            >li {
                                @include running();
                                color: $patina;
                                position: relative;
                                list-style: none;
                                padding: 16px 0 0 10%;
                                &:before {
                                    color: $coral;
                                    font-weight: 400;
                                    position: absolute;
                                    top: 20px;
                                    left: 0;
                                    counter-increment: cardinal;
                                    content: counter(cardinal);
                                    display: block;
                                }
                                &:first-of-type {
                                    padding-top: 0;
                                    &:before {
                                        top: 0;
                                    }
                                }
                            }
                        }
                    }
                    >.note {
                        padding: 8%;
                    }
                }
            }
        }
        &.content-gallery {
            background: $sand;
            padding: 2% 0;
            margin-bottom: 1px;
            >.content {
                background: transparent;
                &.base-layout {
                    @include base-layout();
                    margin-bottom: 2%;
                }
                >.items {
                    margin: 2% 0;
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
                    grid-gap: 24px;
                    >.item {
                        border-top: 1px solid $line;
                        padding-top: 4%;
                        >.lockup {
                            .title {
                                @include running(large);
                                font-family: Empirica, sans-serif;
                                font-weight: 300;
                                color: $oxide;
                                margin: 8% 0 2%;
                            }
                            .external-link {
                                @include border-radius(24px);
                                font-family: Empirica, sans-serif;
                                display: inline-block;
                                margin: 2% 0;
                                padding: 5px 16px 8px;
                                background: #fff;
                                border: 0;
                                &:hover {
                                    background: $moss;
                                    color: #fff;
                                    border: 0;
                                }
                            }
                            .address {
                                @include running(note);
                                @include uppercase();
                                line-height: 22px;
                                font-family: Empirica, sans-serif;
                                font-weight: 500;
                                color: $dust;
                                margin: 2% 0;
                            }
                        }
                        .description {
                            @include running(small);
                            font-family: Empirica, sans-serif;
                            color: $stone;
                            margin-top: 8%;
                        }
                        &.definition {
                            text-align: center;
                            border: 0;
                            padding: 12%;
                            background: $porcelain;
                            dt {
                                @include running(large);
                                font-family: Empirica, sans-serif;
                                font-weight: 300;
                                color: $oxide;
                                .phonetic {
                                    @include running();
                                    font-family: Empirica, sans-serif;
                                    display: block;
                                    font-style: italic;
                                    letter-spacing: 2px;
                                    color: $stone;
                                    margin: 0 0 24px;
                                }
                            }
                            dd {
                                margin: 0;
                                @include running(note);
                                @include uppercase();
                                line-height: 22px;
                                font-family: Empirica, sans-serif;
                                font-weight: 500;
                                color: $dust;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 480px) {
                >.content {
                    background: transparent;
                    >.items {
                        display: block;
                        >.item {
                            margin-top: 8%;
                            padding-top: 8%;
                            >.lockup {
                                >.title {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.quote {
            @include base-layout();
            max-width: 800px;
            margin-top: 6%;
            margin-bottom: 4%;
            >.content {
                >blockquote {
                    >q {
                        @include running(large);
                        color: #000;
                        position: relative;
                        quotes: "“" "”" "‘" "’";
                        display: block;
                        width: 70%;
                        &:before {
                            display: block;
                            font-size: 140px;
                            line-height: 140px;
                            height: 65px;
                            width: 65px;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        &.gallery {
            @include base-layout();
            @include flex();
            margin-bottom: 4%;
            >figure {
                >.graphic {
                    position: relative;
                    >img {
                        @include responsive-image();
                    }
                    >.commerce {
                        display: none;
                        position: absolute;
                        right: 2%;
                        bottom: 2%;
                        >.button-group {
                            >a {
                                @include border-radius(5px);
                                @include box-shadow(0,5px,15px,0,rgba(0,0,0,0.4));
                                background: rgba(255,255,255,0.8);
                                padding: 10px 15px;
                                display: block;
                                color: #144cda;
                            }
                        }
                    }
                }
                >figcaption {
                    padding: 2% 0 0;
                    color: #84837e;
                    font-size: 16px;
                    max-width: 90%;
                    a {
                        color: #144cda;
                    }
                    @media screen and (max-width: 480px) {
                        display: none;
                    }
                }
                &:hover {
                    >.graphic {
                        >.commerce {
                            display: block;
                        }
                    }
                }
            }
            &.video {
                width: auto;
                display: block;
                padding: 4%;
                >.video-constraint {
                    max-width: 1440px;
                    margin: 0 auto;
                    >.video-container {
                        position: relative;
                        /* You want to absolutely position the video within a wrapper that sets vertical padding that matches the video's aspect ratio. To get the padding/aspect ratio, divide the video's height by the width and multiply by 100 to get a percentage.*/
                        padding-bottom: 56.25%;
                        height: 0;
                        overflow: hidden;
                        width: auto;
                        iframe {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                @media screen and (max-width: 880px) {
                    padding: 0;
                    margin: 8% 0;
                }
            }
            &.single-vertical {
                >figure {
                    max-width: 760px;
                    margin: 0 auto;
                    >figcaption {
                        max-width: 720px;
                        margin: 0 auto;
                    }
                }
            }
            &.compound-grid {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 24px;
                display: grid;
                margin-bottom: 1px;
                &.grid-4-column {
                    grid-template-columns: repeat(4, 1fr);
                }
                &.grid-3-column {
                    grid-template-columns: repeat(3, 1fr);
                    @media screen and (max-width: 640px) {
                        display: block;
                    }
                }
                &.in-text {
                    display: block;
                    margin-bottom: 2%;
                    figure {
                        display: block;
                        max-width: 720px;
                        margin: 0 auto;
                    }
                }
                @media screen and (min-width: 1661px) {
                    margin-bottom: 8%;
                }
                >figure {
                    padding: 0;
                    >figcaption {
                        padding: 1% 0 2%;
                        &.label {
                            max-width: unset;
                            display: block;
                            padding: 2% 0;
                            p {
                                @include uppercase();
                                color: $tin;
                                text-align: center;
                            }
                        }
                    }
                    &.full-width {
                        grid-column-start: 1;
                        grid-column-end: 3;
                    }
                }
                &.five {
                    grid-template-columns: repeat(4, 1fr);
                    >figure {
                        &:nth-of-type(1) {
                            grid-column-start: 1;
                            grid-column-end: 3;
                            grid-row-start: 1;
                            grid-row-end: 3;
                        }
                        &:nth-of-type(2) {
                            grid-column-start: 3;
                            grid-column-end: 4;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                        &:nth-of-type(3) {
                            grid-column-start: 4;
                            grid-column-end: 5;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                        &:nth-of-type(4) {
                            grid-column-start: 3;
                            grid-column-end: 4;
                            grid-row-start: 2;
                            grid-row-end: 3;
                        }
                        &:nth-of-type(5) {
                            grid-column-start: 4;
                            grid-column-end: 5;
                            grid-row-start: 2;
                            grid-row-end: 3;
                        }
                    }
                }
                @media screen and (max-width: 640px) {
                    >figure {
                        grid-column-start: 1;
                        grid-column-end: 3;
                    }
                }
            }
            &.type-full-screen {
                display: block;
                width: auto;
                margin-bottom: 1px;
                figcaption {
                    padding: 1%;
                    text-align: center;
                    max-width: 100%;
                }
                & + .running {
                    margin-top: 4%;
                }
                @media screen and (min-width: 1661px) {
                    margin-bottom: 8%;
                }
            }
            &.uneven {
                margin-bottom: 1px;
                >figure {
                    width: 50%;
                    &:nth-of-type(2) {
                        padding: 8%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                }
                &.flipped {
                    >figure {
                        &:nth-of-type(1) {
                            padding: 8%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                        }
                        &:nth-of-type(2) {
                            padding: 0;
                            display: block;
                        }
                    }
                }
                @media screen and (max-width: 480px) {
                    display: block;
                    >figure {
                        width: 100%;
                        margin-bottom: 1px;
                        &:nth-of-type(2) {
                            padding: 0;
                            display: block;
                        }
                    }
                    &.flipped {
                        >figure {
                            &:nth-of-type(1) {
                                padding: 0;
                                display: block;
                            }
                        }
                    }
                }
            }
            &.compound-grid-uneven {
                grid-template-columns: 1fr 2.2641fr;
                grid-gap: 24px;
                display: grid;
                >figure {
                    grid-template-rows: auto;
                    grid-gap: 0;
                    display: grid;
                    >.graphic {
                    }
                    >figcaption {
                        padding: 20px 0 0;
                    }
                }
                @media screen and (max-width: 480px) {
                    display: block;
                    >figure {
                        margin-bottom: 1px;
                    }
                }
            }
            @media screen and (max-width: 880px) {
                margin-bottom: 1px;
                margin-left: 2%;
                margin-right: 2%;
                & + .running {
                    margin-top: 12%;
                }
            }
        }
        &.with-master-caption {
            margin-bottom: 0;
            @media screen and (min-width: 1661px) {
                margin-bottom: 0 !important;
            }
        }
        &.colophon {
            text-align: center;
            padding: 8% 0;
            margin-bottom: 0;
            >.content {
                p {
                    @include running(note);
                    @include uppercase();
                    line-height: 32px;
                    font-family: Empirica, sans-serif;
                    font-weight: 500;
                    color: $dust;
                    text-align: center;
                }
            }
            >.content {
                >.items {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
                    >.item {
                        margin: 0 2% 4%;
                        >.value {
                            @include running();
                        }
                        >.label {
                            @include running(small-caps);
                        }
                    }
                }
                >.flourish {
                    @include running(large);
                }
            }
        }
    }
}